<template>
  <div role="status" class="w-full sm:w-74 h-96 animate-pulse border border-gray-300 rounded-xxl" :aria-label="$t('text-loading')">
    <div class="relative flex items-center justify-center h-50 max-w-sm bg-gray-300 rounded-t-xxl dark:bg-gray-700 mb-4">
      <div class="block overflow-hidden absolute top-0 left-0 bottom-0 right-0 box-border m-0">
        <Image
          :src="siteSettings.product.placeholderImage[config.public.layoutDesign]"
          layout="fill"
          object-fit="cover"
        />
      </div>
    </div>
    <div class="mx-5 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-3/4 mb-4"/>
    <div class="mx-5 h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-1/2 mb-2.5"/>
  </div>
</template>

<script setup>
import { siteSettings } from "@settings/site.settings";
import Image from "@components/ui/image";

const config = useRuntimeConfig();

</script>
