import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useSelectedStore } from '@stores/selected'

export const useGeolocation = () => {
  const route = useRoute();
  const store = useSelectedStore();
  const config = useRuntimeConfig();
  const { $eventBus } = useNuxtApp();

  const place = useState('geolocation.place', () => {});
  const coordinates = useState('geolocation.coordinates', () => ({
    latitude: _.get(route.query, 'latitude', null),
    longitude: _.get(route.query, 'longitude', null)
  }));
  const error = ref(null);

  const isPlaceSynced = () => {
    return coordinates.value.latitude === _.get(place.value, 'geometry.location.latitude')
      && coordinates.value.longitude === _.get(place.value, 'geometry.location.longitude');
  }

  const getPlace = async () => {
    if (isPlaceSynced()) {
      return place.value;
    }

    if (!coordinates.value.latitude || !coordinates.value.longitude) {
      return null;
    }

    try {
      place.value = await $fetch(`${config.public.appBaseUrl}${API_ENDPOINTS.SERVICE_GEOCODE_COORDINATES}`, {
        method: 'GET',
        params: {
          latitude: coordinates.value.latitude,
          longitude: coordinates.value.longitude,
          type: 'locality'
        }
      });

      $eventBus.emit('place:update');
      return place.value;
    } catch (error) {
      error.value = 'Error fetching location from coordinates';
    }

    return null;
  };

  const setPlace = (value) => {
    place.value = value;
  }

  const withLocation = async (callback) => {
    if (import.meta.client) {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            coordinates.value.latitude = position.coords.latitude;
            coordinates.value.longitude = position.coords.longitude;
            store.setCoordinates(coordinates.value);
            await callback();
          },
          async (err) => {
            error.value = err;
            await callback();
          }
        );
      } else {
        error.value = "Geolocation is not supported by this browser.";
        // await callback();
      }
    } else {
      error.value = "Geolocation is not available in SSR.";
      // await callback();
    }
  };

  return { coordinates, error, withLocation, place, isPlaceSynced, getPlace, setPlace };
};
