<template>
    <form noValidate @submit.prevent="">
        <div v-if="!modelValue?.id" class="italic text-sm text-body">
            {{  $t('text-adding-watchdog-hint') }}
        </div>
        <Selectbox
            v-model="modelValue.type"
            :label="$t('text-type')"
            :options="types"
            name="types"
            variant="outline"
            class-name="mt-5"
        />
        <Input
            v-model="modelValue.fulltext"
            :label="$t('text-contains-keyword')"
            name="fulltext"
            type="text"
            variant="outline"
            class-name="mt-5"
        />
        <Transition name="fade" mode="out-in">
          <div v-if="!modelValue.fulltext" class="italic mt-2 text-sm text-body">
            {{ $t('text-contains-keyword-hint') }}
          </div>
        </Transition>
        <Selectbox
            v-model="modelValue.idCategory"
            :label="$t('text-category')"
            :placeholder="$t('text-category')"
            :options="categories"
            name="category"
            variant="outline"
            class-name="mt-5"
        />
        <Transition name="fade" mode="out-in">
          <div v-if="!modelValue.idCategory" class="italic mt-2 text-sm text-body">
            {{ $t('text-watchdog-category-hint') }}
          </div>
        </Transition>
        <label
            htmlFor="city"
            class="flex flex-row items-center justify-between text-body-dark font-semibold text-sm leading-tight mt-5"
        >
            <div class="truncate first-letter:uppercase">{{ $t('text-distance-name-or-zip') }}</div>
        </label>
        <div class="flex relative h-12 mt-3">
            <input
                ref="inputRef"
                name="city"
                :class="[ 'w-full h-full flex item-center appearance-none transition duration-300 ease-in-out text-body text-sm placeholder-gray-500 overflow-hidden focus:outline-none focus:ring-0 border border-border-base rounded px-5' ]"
                @change="handleChange"
                @keyup.enter="handleChange"
            >
            <button
                v-if="showMapPinButton"
                type="button"
                class="cursor-pointer h-full w-10 flex items-center justify-center absolute text-primary-dark transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-hover end-0"
                :title="$t('text-allow-geolocation-here')"
                @click="handleGetCoordinates"
            >
                <MapPinIcon class="w-4 h-4" />
            </button>
            <button
                v-else
                type="button"
                class="cursor-pointer h-full w-10 flex items-center justify-center absolute text-primary-dark transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-hover end-0"
                @click="handleClear"
            >
                <span class="sr-only">{{ $t('text-close') }}</span>
                <CloseIcon class="w-3.5 h-3.5 md:w-3 md:h-3" />
            </button>
        </div>
        <Input
            v-if="modelValue.location"
            v-model="modelValue.maxKmFromLocation"
            :label="$t('text-distance')"
            :placeholder="$t('text-watchdog-distance-location-hint')"
            :error="errors?.maxKmFromLocation?.message ? $t(errors.maxKmFromLocation.message) : ''"
            name="maxKmFromLocation"
            type="number"
            variant="outline"
            class-name="mt-5"
            :minvalue="0"
            :required="true"
        />
        <Input
            v-else
            v-model="modelValue.maxKmFromHome"
            :label="$t('text-distance')"
            :placeholder="$t('text-watchdog-distance-home-hint')"
            name="maxKmFromHome"
            type="number"
            variant="outline"
            class-name="mt-5"
            :minvalue="0"
        />
        <ProductWatchdogParameters v-model="modelValue.parameters" :id-category="modelValue.idCategory" class="mt-5" />
        <div ref="mapRef" class="hidden" />
    </form>
</template>

<script setup>
import _ from 'lodash-es';
import { CATEGORY_TYPE, PRODUCT_TYPE } from "@utils/constants";
import { useUIStore } from '@stores/ui';
import { useCustomerStore } from '@stores/customer';
import { useConfigStore } from '@stores/config';
import MapPinIcon from "@components/icons/map-pin-icon";
import CloseIcon from "@components/icons/close-icon";
import Input from "@components/ui/input";
import Selectbox from "@components/ui/selectbox";
import ProductWatchdogParameters from "@components/watchdog/product-watchdog-parameters";

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
  errors: {
      type: Object,
      default: () => {}
  }
});

const config = useRuntimeConfig();
const { t } = useI18n();
const { $eventBus } = useNuxtApp();
const configs = useConfigStore();

const types = ref(_.concat([
  { id: null, name: '' },
  { id: PRODUCT_TYPE.OFFER, name: t('text-offer') },
  { id: PRODUCT_TYPE.WANTED, name: t('text-request') },
], configs.auctionFeature ? [
  { id: PRODUCT_TYPE.AUCTION, name: t('text-auction') },
] : []));

const ui = useUIStore();
const customer = useCustomerStore();
const geolocation = useGeolocation();
const flatCategories = useFlatCategories(CATEGORY_TYPE.ESHOP);

const inputRef = ref(null);
const mapRef = ref(null);
const map = ref(null);
const service = ref(null);
const autocomplete = ref(null);
const showMapPinButton = ref(!props.modelValue.location);
const categories = ref(_.concat([{ id: null, name: ''}], flatCategories.items.value));

const handleGetCoordinates = async () => {
  geolocation.withLocation(async () => {
    await geolocation.getPlace();
  });
};

const handleChange = () => _.delay(() => {
  if (props.modelValue.location !== inputRef.value.value || (props.modelValue.location === '' && inputRef.value.value === '')) {
    inputRef.value.value = '';
  }
}, 200);

const handleClear = () => {
  props.modelValue.location = null;
  inputRef.value.value = null;
  showMapPinButton.value = !props.modelValue.location;
  props.modelValue.latitude = null;
  props.modelValue.longitude = null;
  props.modelValue.maxKmFromHome = '';
  props.modelValue.maxKmFromLocation = '';
}

const updateByGeolocationPlace = () => {
  props.modelValue.location = geolocation.place.value?.formatted_address || '';
  inputRef.value.value = props.modelValue.location;
  showMapPinButton.value = !props.modelValue.location;
  if (geolocation.place.value?.geometry?.location) {
    props.modelValue.latitude = geolocation.coordinates.value.latitude = geolocation.place.value.geometry.location.latitude = geolocation.place.value.geometry.location.lat();
    props.modelValue.longitude = geolocation.coordinates.value.longitude = geolocation.place.value.geometry.location.longitude = geolocation.place.value.geometry.location.lng();
  }
}

const initAutocomplete = () => {
  if (!window.google) return;

  map.value = map.value || new window.google.maps.Map(mapRef.value, { mapId: config.public.googleMapId });
  service.value = service.value || new window.google.maps.places.PlacesService(map.value);

  if (autocomplete.value) {
    autocomplete.value.unbindAll();
    autocomplete.value = null;
  }

  const input = inputRef.value;
  const options = {
    types: ['locality', 'postal_code'],
    language: customer?.language?.code || config.public.defaultLanguage
  };

  autocomplete.value = new window.google.maps.places.Autocomplete(input, options);

  autocomplete.value.addListener('place_changed', () => {
    geolocation.place.value = autocomplete.value.getPlace() || {};

    const request = {
      placeId: geolocation.place.value.place_id,
      language: customer?.language?.code || config.public.defaultLanguage
    };

    service.value.getDetails(request, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        geolocation.place.value = place;
      }
      updateByGeolocationPlace();
    });

    updateByGeolocationPlace();
  });
}

const placeUpdate = () => {
    if (_.startsWith(ui.modalView, 'PRODUCT_WATCHDOG')) {
        props.modelValue.location = geolocation.place.value?.formatted_address || geolocation.place.value?.formattedAddress || '';
        inputRef.value.value = props.modelValue.location;
        showMapPinButton.value = !props.modelValue.location;
    }
};

onMounted(async () => {
  if (import.meta.client && window && window.google) {
    initAutocomplete();
  }
  if (import.meta.client && props.modelValue.location) {
    inputRef.value.value = props.modelValue.location;
  }
  $eventBus.on('place:update', placeUpdate);
});

onUnmounted(() => {
  $eventBus.off('place:update', placeUpdate);
});

</script>

<style scoped>
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease;
}
</style>
