<template>
    <div
        v-if="!auth.isLoggedIn || (customer.loaded && !customer?.setting?.notification?.email.sendContentPostNewsPublished)"
        class="text-sm hover:underline text-right cursor-pointer max-w-2xs flex items-center justify-end space-x-1"
        @click="handleEnablesendContentPostNewsPublished"
    >
        <BellSolid class="w-5 h-5 flex-shrink-0" />
        <div>{{ $t('text-enable-settings-send-content-post-news-created') }}</div>
    </div>
</template>

<script setup>
import _ from 'lodash-es';
import { useAuthStore } from '@stores/auth';
import { useCustomerStore } from '@stores/customer';
import BellSolid from "@components/icons/solid/bell-solid";

const { t } = useI18n();
const { $toast } = useNuxtApp();
const auth = useAuthStore();
const customer = useCustomerStore();

const handleEnablesendContentPostNewsPublished = async () => {
    if (!checkAuth()) {
        return;
    }
    _.assignIn(customer.setting.notification.email, { sendContentPostNewsPublished: true });
    const result = await settingNotificationEmailUpdate(customer.setting.notification.email);
    if (result === null) {
        $toast.error(t('error.setting.notification.email.update'));
    } else {
        $toast.success(t('success.setting.notification.email.update'));
    }
}

</script>
