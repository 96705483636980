import { API_ENDPOINTS } from "@utils/api/endpoints";

export default async function (body) {
  const { data, error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_PRODUCT_WATCHDOG, {
    method: 'POST',
    body
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'product-watchdog-create',
      level: 'error',
      extra: { error: error.value }
    });

    return null;
  }

  return data.value;
}
