<template>
  <div class="bg-light w-screen md:max-w-md h-screen md:h-auto flex flex-col justify-center overflow-hidden">
    <Scrollbar class="py-6 px-5 sm:p-8 w-full max-h-screen">
        <h2 class="flex font-semibold text-lg md:text-xl text-bolder mb-2">
            {{ $t('text-editing-watchdog') }}
        </h2>
        <hr class="mb-2 md:mb-4">
        <ProductWatchdogForm v-model="watchdog" :errors="errors" />
        <Alert
          v-if="errorMsg"
          variant="error"
          :message="errorMsg"
          class="w-full mb-5"
          :closeable="true"
          :on-close="() => errorMsg = ''"
        />
        <div class="flex flex-col sm:flex-row items-center justify-between w-full">
            <Button
                class="sm:mr-3 mt-5 sm:mt-0 w-full sm:w-fit"
                variant="outline"
                :on-click="handleClose"
            >
                {{ $t('text-cancel') }}
            </Button>
            <Button
                class="mt-5 sm:mt-0 w-full sm:w-fit"
                :disabled="loading"
                :loading="loading"
                :on-click="handleSubmit"
            >
                {{ $t('text-save') }}
            </Button>
        </div>
    </Scrollbar>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { PRODUCT_TYPE } from "@utils/constants";
import { useSelectedStore } from '@stores/selected';
import Scrollbar from "@components/ui/scrollbar";
import Button from "@components/ui/button";
import ProductWatchdogForm from "@components/watchdog/product-watchdog-form";
import Alert from "@components/ui/alert";

const { $eventBus } = useNuxtApp();
const store = useSelectedStore();
const loading = ref(false);
const errors = ref({});
const errorMsg = ref('');
const watchdog = ref(_.assignIn(_.cloneDeep(store.productWatchdog || {}), { idCategory: store.productWatchdog?.category?.id || null }));

const handleClose = () => {
  $eventBus.emit('modal:close');
};

const isValid = () => {
  errors.value = {};
  errorMsg.value = '';
  if (watchdog.value.location && !watchdog.value.maxKmFromLocation) {
    errors.value.maxKmFromLocation = { message: 'error.watchdog.maxKmFromLocation.required' };
  }
  if (!_.every(_.values(errors.value), _.isEmpty)) {
    errorMsg.value = 'error-some-fields-required';
    $eventBus.emit('focus:error', _.chain(errors.value)
      .keys()
      .first()
      .value()
    );
    return false;
  }

  return true;
}

const handleSubmit = async () => {
  if (!isValid()) {
    return false;
  }
  loading.value = true;

  const body = {
    idCategory: watchdog.value.idCategory ? +watchdog.value.idCategory : null,
    type: _.includes(PRODUCT_TYPE, watchdog.value.type) ? watchdog.value.type : null,
    fulltext: watchdog.value.fulltext ? watchdog.value.fulltext : null,
    maxKmFromHome: watchdog.value.maxKmFromHome ? +watchdog.value.maxKmFromHome : null,
    maxKmFromLocation: watchdog.value.maxKmFromLocation ? +watchdog.value.maxKmFromLocation : null,
    location: watchdog.value.location ? watchdog.value.location : null,
    latitude: +watchdog.value.latitude ? +watchdog.value.latitude : null,
    longitude: +watchdog.value.longitude ? +watchdog.value.longitude : null,
    parameters: _.chain(watchdog.value.parameters || [])
        .filter(item => watchdog.value.idCategory ? _.some(item.idCategories, cat => cat === +watchdog.value.idCategory) : true)
        .map(item => ({
            idParameter: item.idParameter,
            idValues: _.chain(item.values).filter(value => value.value).map(value => value.id).value()
        })).filter(item => !_.isEmpty(item.idValues)).value()
  };

  const data = await productWatchdogUpdate(watchdog.value.id, body);
  if (data) {
    _.assignIn(store.productWatchdog, {
        ...data,
        parameters: setupParameters(
            data.parameters || [],
            _.chain(data.parameters || []).map(parameter => parameter?.value?.id).value()
        )
    });
    handleClose();
    $eventBus.emit('toast:success', 'success.product-watchdog.update');
  } else {
    $eventBus.emit('toast:error', 'error.product-watchdog.update');
  }
  loading.value = false;
}

</script>
