<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog as="div" :class="[ 'fixed inset-0 overflow-y-auto', className ]" @close="modalClose">
      <div class="min-h-full md:p-5 text-center">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-gray-900 bg-opacity-50 w-full h-full" />
          </TransitionChild>

          <span
            class="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="inline-block min-w-content max-w-full overflow-hidden text-start align-middle transition-all relative bg-transparent md:rounded-panel"
            >
              <button
                ref="cancelButtonRef"
                :aria-label="$t('text-close')"
                :class="[ 'inline-block outline-none focus:outline-none absolute end-4 top-5 z-[60] w-4 h-4', closeClassName ]"
                @click="modalClose"
              >
                <span class="sr-only">{{ $t("text-close") }}</span>
                <CloseIcon class="w-4 h-4" />
              </button>
              <slot />
            </DialogPanel>
          </TransitionChild>
        </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogOverlay } from '@headlessui/vue'
import { useSelectedStore } from '@stores/selected';
import CloseIcon from '@components/icons/close-icon'

defineProps({
  open: {
    type: Boolean,
    default: false
  },
  className: {
    type: String,
    default: 'z-40'
  },
  closeClassName: {
    type: String,
    default: 'text-bolder'
  }
})

const { $eventBus } = useNuxtApp();
const store = useSelectedStore();

const modalClose = () => {
  $eventBus.emit('modal:close');
  if (store.action === 'RETURN_TO_PRODUCT_MODAL') {
    $eventBus.emit('modal:open', 'PRODUCT_DETAILS');
    store.setAction(null);
  } else if (store.action === 'RETURN_TO_CONTENT_MODAL') {
    $eventBus.emit('modal:open', 'CONTENT_DETAILS');
    store.setAction(null);
  } else if (store.action === 'RETURN_TO_NEWS_MODAL') {
    $eventBus.emit('modal:open', 'NEWS_DETAILS');
    store.setAction(null);
  } else if (store.action === 'RETURN_TO_SHOP_INFO') {
    $eventBus.emit('modal:open', 'SHOP_INFO');
    store.setAction(null);
  }
}

</script>
