<template>
  <div class="w-full">
    <ProductWatchdogParameter
      v-for="parameter in categoryParameters"
      :key="parameter.idParameter"
      :parameter="parameter"
    />
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import ProductWatchdogParameter from "@components/watchdog/product-watchdog-parameter";

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: Array,
    required: true
  },
  idCategory: {
    type: [String, Number],
    default: 0
  }
});

const parameters = ref(_.cloneDeep(props.modelValue));

const categoryParameters = computed(() => _.filter(parameters.value, item => _.some(item.idCategories, cat => cat === +props.idCategory)));

watch(() => parameters.value, (value) => {
  emit('update:modelValue', value);
}, { deep: true });

</script>
