<template>
  <div class="flex flex-col items-center justify-center mx-0 md:mx-5 mt-5">
    <div class="max-w-2xl flex flex-col items-center justify-center">
      <Avatar
        :src="variant?.supplier?.logo"
        class="cursor-pointer"
        :title="variant?.supplier?.name"
        size="reference"
        @click="navigate"
      />
      <h3 class="text-lg uppercase font-bold text-center my-2 line-clamp-1" v-text="variant?.supplier?.name" />
      <div v-if="variant?.ratingScore" class="flex flex-row items-center justify-center space-x-2 mt-2">
        <StarSolid v-for="(index, idx) in _.range(0, variant?.ratingScore)" :key="idx" class="w-6 h-6 text-orange hover:text-orange-hover" />
      </div>

      <div class="prose max-w-none mt-4 mb-2 px-5" v-html="localized(variant, 'content', localized(variant, 'perex'))" />
    </div>

    <ReferenceDetailGallery v-if="variant?.image?.url || _.get(variant, 'images.0.url', null)" :variant="variant" class="px-5 max-w-2xl" />

    <div class="flex flex-row items-center justify-start space-x-2.5 mt-6 md:mt-4 mb-9">
      <ContentViews :variant="variant" />
      <ContentLikes :variant="variant" />
    </div>

  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import ContentViews from "@components/content/content-details/content-views";
import ContentLikes from "@components/content/content-details/content-likes";
import ReferenceDetailGallery from "@components/reference/reference-details/reference-detail-gallery";
import Avatar from "@components/ui/avatar";
import StarSolid from "@components/icons/solid/star-solid";

const props = defineProps({
  variant: {
    type: Object,
    required: true
  },
  size: {
    type: String,
    default: 'page' //page, modal
  }
})

const { $eventBus } = useNuxtApp();

const closeModal = () => {
    $eventBus.emit('modal:close');
}

const navigate = async () => {
    closeModal();
    await navigatorTo(translatePath(ROUTES.CODE.SUPPLIER, slugify(props.variant?.supplier?.id, props.variant?.supplier?.name)));
}

</script>
