<template>
  <div class="py-6 px-5 sm:p-8 bg-light w-screen md:max-w-md h-screen md:h-auto flex flex-col justify-center">
    <h2 class="flex font-semibold text-lg md:text-xl text-bolder mb-2">
      {{ $t('text-product-image-edit') }}
    </h2>
    <hr class="mb-2 md:mb-4" >
    <div class="flex flex-col sm:flex-row justify-between w-full">
      <TextArea
        v-model="description"
        :label="$t('text-description')"
        name="description"
        type="text"
        variant="outline"
        class-name="w-full mb-5"
        :error="errors?.description?.message ? $t(errors.description.message) : ''"
      />
    </div>
    <div class="flex flex-col sm:flex-row justify-end w-full">
      <Button
          class="ms-auto"
          :on-click="handleUse"
      >
        {{ $t('text-use') }}
      </Button>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { useSelectedStore } from '@stores/selected';
import { useCustomerStore } from '@stores/customer';
import { siteSettings } from "@settings/site.settings";
import TextArea from "@components/ui/text-area";
import Button from "@components/ui/button";

const store = useSelectedStore();
const customer = useCustomerStore();
const { $eventBus } = useNuxtApp();
const errors = ref({});
const description = ref(_.get(store.image, 'languages.0.description', ''));

const handleUse = async () => {
  store.image.languages = [
    {
      idLanguage: customer.language?.id || siteSettings.language.id, //@TODO
      description: description.value
    }
  ];
  store.setImage(null);
  $eventBus.emit('modal:close');
}

</script>
