<template>
<svg width="275" height="100" viewBox="0 0 275 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M192.32 48.55L82.9301 29.75C80.2101 29.28 77.6201 31.11 77.1601 33.83L74.4501 49.6C73.9801 52.32 75.8101 54.91 78.5301 55.37L187.93 74.17C193.37 75.11 198.54 71.45 199.48 66.01L200.5 60.1C201.44 54.66 197.78 49.49 192.34 48.55H192.32Z" stroke="#E1A140" stroke-width="2" stroke-miterlimit="10"/>
<path d="M171.637 58.0068C172.408 53.5162 166.746 48.7955 158.99 47.4627C151.233 46.1299 144.32 48.6898 143.548 53.1803C142.777 57.6709 148.439 62.3916 156.195 63.7244C163.952 65.0572 170.865 62.4973 171.637 58.0068Z" stroke="#E1A140" stroke-width="2" stroke-miterlimit="10"/>
<path d="M89.8801 30.9501L85.8301 56.6301" stroke="#E1A140" stroke-width="2" stroke-miterlimit="10"/>
<path d="M151.78 62.52L150.95 67.81" stroke="#E1A140" stroke-width="2" stroke-miterlimit="10"/>
<path d="M154.99 42.13L154.1 47.81" stroke="#E1A140" stroke-width="2" stroke-miterlimit="10"/>
<path d="M158.12 63.42L157.27 68.81" stroke="#E1A140" stroke-width="2" stroke-miterlimit="10"/>
<path d="M161.32 43.12L160.58 47.79" stroke="#E1A140" stroke-width="2" stroke-miterlimit="10"/>
</svg>
</template>
