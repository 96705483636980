<template>
  <div v-if="variant?.product?.status !== STATUS.CLOSED" class="w-full my-2">
    <div :class="['flex flex-row items-center', variant?.product?.type === PRODUCT_TYPE.AUCTION && position === 'inside' ? 'justify-end' : 'justify-between']">
        <template v-if="(variant?.product?.type !== PRODUCT_TYPE.AUCTION && position === 'inside') || (variant?.product?.type === PRODUCT_TYPE.AUCTION && position === 'outside')">
            <span v-if="(auctionPrice || _.get(variant, 'price', null)) === null" class="flex items-center">
                <ins class="text-base font-bold text-primary-dark no-underline">
                {{ $t('text-price-negotiable') }}
                </ins>
            </span>
            <span v-else class="flex items-center">
                <ins class="text-base font-bold text-primary-dark no-underline">
                {{ auctionPrice ? $t('text-current-price') + ':' : '' }}
                {{ basePrice ? basePrice : price }}
                </ins>
                <del v-if="discount" class="text-sm md:text-base font-normal text-muted ms-2">
                {{ price }}
                </del>
            </span>
        </template>
        <VariantShare v-if="position === 'inside'" :variant="variant" />
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { PRODUCT_TYPE, STATUS } from "@utils/constants";
import usePrice from "@utils/use-price";
import VariantShare from "@components/product/product-details/variant-share";

const props = defineProps({
  variant: {
    type: Object,
    required: true
  },
  position: {
    type: String,
    default: 'inside' //inside, outside
  }
})

const auctionPrice = computed(() => _.get(props.variant, 'auction.currentBid', null) || _.get(props.variant, 'auction.startingBid', null));

const price = computed(() => usePrice({
  amount: auctionPrice.value || +props.variant?.price,
  baseAmount: auctionPrice.value || +props.variant?.sale_price,
  currencyCode: props.variant?.currency?.code
})?.price);

const basePrice = computed(() => usePrice({
  amount: auctionPrice.value || +props.variant?.price,
  baseAmount: auctionPrice.value || +props.variant?.sale_price,
  currencyCode: props.variant?.currency?.code
})?.basePrice);

const discount = computed(() => usePrice({
  amount: auctionPrice.value || +props.variant?.price,
  baseAmount: auctionPrice.value || +props.variant?.sale_price,
  currencyCode: props.variant?.currency?.code
})?.discount);

</script>
