import _ from 'lodash-es';
import { defineStore } from 'pinia'
import { sitePages } from "@settings/site-pages.settings";
import { CONFIG_KEY } from "@utils/constants";

export const useConfigStore = defineStore(CONFIG_KEY, () => {
  const config = useRuntimeConfig();

  const loaded = useState('config.loaded', () => false);
  const setLoaded = (value) => {
    loaded.value = value;
  }

  const publicConfig = useState('config.public', () => []);
  const setPublicConfig = (value) => {
    publicConfig.value = value;
  }

  const title = computed(() => _.get(_.find(publicConfig.value || [], { identifier: 'title' }), 'value', sitePages[config.public.layoutDesign].title));
  const description = computed(() => _.get(_.find(publicConfig.value || [], { identifier: 'description' }), 'value', sitePages[config.public.layoutDesign].description));
  const displaySpeed = computed(() => parseInt(_.get(_.find(publicConfig.value || [], { identifier: 'display_speed' }), 'value', '5000')));
  const auctionFeature = computed(() => !!parseInt(_.get(_.find(publicConfig.value || [], { identifier: 'auction_feature' }), 'value', '0')));

  return {
    loaded, setLoaded,
    publicConfig, setPublicConfig,
    title, description, displaySpeed, auctionFeature
  }
})
