<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="368.000000pt"
    height="338.000000pt"
    viewBox="0 0 368.000000 338.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,338.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M2335 3283 c-11 -3 -38 -18 -60 -33 -63 -43 -79 -49 -150 -59 -126
-18 -147 -73 -69 -182 77 -108 77 -109 49 -168 -14 -28 -46 -83 -71 -123 -26
-40 -59 -100 -74 -133 -27 -60 -96 -135 -124 -135 -8 0 -46 -37 -86 -82 -39
-46 -81 -88 -93 -95 -63 -33 -193 -54 -473 -78 -217 -18 -267 -31 -399 -94
-97 -47 -125 -73 -125 -113 0 -6 -15 -29 -34 -52 -42 -51 -119 -195 -140 -261
-8 -27 -22 -98 -31 -157 -8 -59 -21 -138 -30 -175 -9 -37 -22 -118 -30 -179
-23 -172 -67 -297 -124 -348 -15 -14 -21 -31 -21 -63 0 -60 -56 -290 -96 -396
-19 -49 -34 -95 -34 -102 0 -43 87 -62 210 -45 41 6 83 11 92 11 36 1 11 79
-37 117 -21 17 -25 28 -25 76 0 36 7 71 19 94 10 20 26 61 35 91 9 30 26 68
39 85 13 17 34 51 47 76 25 51 107 163 193 263 32 38 80 101 106 141 56 82 81
108 81 82 0 -9 10 -26 21 -37 27 -27 80 -21 116 13 22 21 24 21 41 5 13 -14
17 -33 15 -87 -2 -78 -15 -115 -67 -190 -42 -60 -43 -72 -16 -131 11 -24 20
-56 20 -71 0 -30 82 -259 105 -295 15 -23 19 -23 160 -22 142 1 145 1 145 23
0 47 -115 136 -177 136 -22 0 -43 57 -50 134 -5 68 -4 78 20 118 14 24 46 62
71 86 50 48 78 111 97 219 7 37 16 72 20 77 18 20 60 -6 91 -55 40 -65 96
-106 167 -120 l56 -12 17 -56 c27 -89 39 -166 48 -313 4 -76 12 -144 18 -150
30 -38 46 -250 28 -364 -8 -48 -7 -53 15 -65 16 -8 72 -13 163 -14 122 -2 141
0 157 16 10 10 21 14 24 9 9 -14 25 -12 25 3 0 7 -14 35 -32 63 -17 27 -49 83
-70 124 l-39 75 2 135 c3 120 0 146 -24 235 -33 121 -47 285 -26 301 8 6 42
22 76 37 34 14 104 51 155 83 108 66 167 90 173 71 2 -7 9 -49 15 -94 6 -45
26 -158 44 -250 50 -251 62 -338 48 -368 -23 -51 -9 -95 30 -95 23 0 38 -30
38 -74 0 -46 16 -86 35 -86 11 0 15 -11 15 -44 0 -53 21 -80 58 -73 14 3 36
-1 47 -9 17 -11 57 -14 178 -12 187 4 186 3 96 107 -57 65 -65 71 -100 71 -30
0 -48 8 -77 35 -83 74 -108 275 -78 625 9 114 13 226 9 291 -5 94 -3 117 21
212 l27 106 -28 153 c-22 120 -27 176 -24 260 1 60 -2 123 -8 140 -8 26 -7 46
5 88 9 30 21 76 27 103 9 38 14 46 29 41 21 -6 124 19 184 46 40 17 44 22 44
54 0 25 4 36 15 36 26 0 13 43 -27 87 -19 20 -19 23 -4 39 24 24 53 84 61 127
4 21 4 80 0 133 -7 91 -8 95 -35 109 -16 8 -48 15 -72 15 -52 0 -60 5 -78 50
-8 19 -35 58 -60 86 -25 29 -53 63 -62 77 -15 22 -24 24 -85 25 -37 0 -88 5
-113 11 -25 6 -62 11 -83 11 -21 0 -46 4 -55 9 -23 12 -102 21 -127 14z"
      />
    </g>
  </svg>
</template>
