<template>
  <button
    :disabled="disabled"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-product-button rounded-full transition-colors border border-product-button bg-product-button hover:border-primary-dark hover:bg-transparent focus:bg-transparent hover:text-primary-dark-hover focus:text-primary-dark-hover"
    @click="onClick"
  >
    {{ $t('text-edit') }}
  </button>
</template>

<script setup>
import { ROUTES } from "@utils/routes";

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false
  }
});

const { $eventBus } = useNuxtApp();

const onClick = async () => {
  $eventBus.emit('modal:close');
  await navigatorTo(translatePath(ROUTES.CODE.AD_EDIT, props.data?.id));
};

</script>
