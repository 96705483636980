<template>
    <div :class="['flex flex-col items-center', className]">
      <div v-if="position === 'top'" class="w-2/3 h-full flex items-center justify-center">
        <NuxtImg
          :src="`/no-result-${config.public.layoutDesign}.webp`"
          :alt="text ? $t(text) : $t('text-no-result-found')"
          class="w-full h-full object-contain"
          loading="lazy"
          :width="250"
          :height="250"
          sizes="250px"
        />
      </div>
      <h3 v-if="text" :class="['w-full text-center text-xl font-semibold my-7', isDetail ? 'text-bolder' : 'text-heading']">
        <div v-for="(line, idx) in $t(text).split(/(?<=\.)/)" :key="idx">{{ line }}</div>
      </h3>
      <slot />
      <div v-if="position === 'bottom'" class="w-2/3 h-full flex items-center justify-center">
        <NuxtImg
          :src="`/no-result-${config.public.layoutDesign}.webp`"
          :alt="text ? $t(text) : $t('text-no-result-found')"
          class="w-full h-full object-contain"
          loading="lazy"
          :width="250"
          :height="250"
          sizes="250px"
        />
      </div>
    </div>
</template>

<script setup>
defineProps({
    className: {
        type: String,
        default: ''
    },
    text: {
        type: String,
        default: ''
    },
    position: {
        type: String,
        default: 'top'
    },
    isDetail: {
      type: Boolean,
      default: false
    }
});

const config = useRuntimeConfig();

</script>
