<template>
    <div
      v-if="!auth.isLoggedIn || (customer.loaded && !customer?.setting?.notification?.email.sendContentPostNewsPublished)"
      :class="[ 'w-10 h-10 cursor-pointer flex-shrink-0 flex items-center justify-center rounded-xl', accent ? 'border border-primary-neutral hover:border-primary-dark-hover hover:bg-primary-dark-hover text-light' : 'border border-primary-dark hover:border-primary-dark-hover hover:bg-primary-dark-hover text-primary-dark hover:text-light' ]"
      :title="$t('text-enable-settings-send-content-post-news-created')"
      @click="handleEnablesendContentPostNewsPublished"
    >
        <BellIcon class="w-4 h-4" />
    </div>
</template>

<script setup>
import _ from 'lodash-es';
import { useAuthStore } from '@stores/auth';
import { useCustomerStore } from '@stores/customer';
import BellIcon from "@components/icons/bell-icon";

const { t } = useI18n();
const { $toast } = useNuxtApp();
const auth = useAuthStore();
const customer = useCustomerStore();

const handleEnablesendContentPostNewsPublished = async () => {
    if (!checkAuth()) {
        return;
    }
    _.assignIn(customer.setting.notification.email, { sendContentPostNewsPublished: true });
    const result = await settingNotificationEmailUpdate(customer.setting.notification.email);
    if (result === null) {
        $toast.error(t('error.setting.notification.email.update'));
    } else {
        $toast.success(t('success.setting.notification.email.update'));
    }
}

</script>
