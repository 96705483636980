<template>
  <div :class="className">
    <label
      v-if="label"
      :htmlFor="name"
      class="flex flex-row items-center justify-between text-body-dark font-semibold text-sm leading-tight mb-3"
    >
      <div class="truncate">{{ label }}<span v-if="required" class="text-red-500">&nbsp;*</span><span v-if="note" class="italic font-light" :title="note">&nbsp;{{ note }}</span></div>
      <div v-if="showMaxlength && maxlength" class="text-xs font-normal">{{ modelValue.length }}/{{ maxlength }}</div>
    </label>
    <input
      :id="name"
      ref="inputField"
      :name="name"
      :type="decimals ? 'text' : type"
      :placeholder="placeholder"
      :value="modelValue"
      :class="[ 'px-4 flex items-center w-full appearance-none transition duration-300 ease-in-out text-bolder text-sm placeholder-gray-500 focus:outline-none focus:ring-0', shadow ? 'focus:shadow' : '', variantClasses[variant], sizeClasses[dimension], disabled ? 'bg-gray-100 cursor-not-allowed' : '', error ? '!border-red-500' : '', inputClassName, classBorder ]"
      :disabled="disabled"
      :required="required"
      :min="minvalue"
      :max="maxvalue"
      :maxlength="maxlength"
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      :aria-invalid="error ? 'true' : 'false'"
      v-bind="$attrs"
      @input="onInput"
      @change="$emit('change', $event)"
      @keyup.enter="onSubmit"
      @keypress="checkNumber"
      @focus="onFocus"
      @blur="onBlur"
    >
    <slot />
    <p v-if="error" class="my-2 text-xs text-red-500">
        {{ error }}
    </p>
  </div>
</template>

<script setup>
import _ from 'lodash-es';

defineOptions({
  inheritAttrs: false
});

const variantClasses = {
  normal:
    "bg-gray-100 border-border-base focus:shadow focus:bg-light focus:border-accent",
  solid:
    "bg-gray-100 border-border-100 focus:bg-light focus:border-accent",
  outline: "border-border-base focus:border-accent",
  line: "ps-0 border-b border-border-base rounded-none focus:border-accent",
};

const sizeClasses = {
  small: "text-sm h-10",
  medium: "h-12",
  big: "h-14",
};

const emit = defineEmits(['update:modelValue', 'change']);

const props = defineProps({
    modelValue: {
        type: [String, Number],
        default: ''
    },
    className: {
        type: String,
        default: ''
    },
    inputClassName: {
        type: String,
        default: ''
    },
    classBorder: {
        type: String,
        default: 'border rounded'
    },
    label: {
        type: String,
        default: ''
    },
    name: {
        type: String,
        default: ''
    },
    placeholder: {
        type: String,
        default: ''
    },
    note: {
        type: String,
        default: ''
    },
    error: {
        type: String,
        default: ''
    },
    type: {
        type: String,
        default: 'text'
    },
    shadow: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    },
    minvalue: {
        type: Number,
        default: null
    },
    maxvalue: {
        type: Number,
        default: null
    },
    maxlength: {
        type: Number,
        default: null
    },
    showMaxlength: {
        type: Boolean,
        default: true
    },
    decimals: {
        type: Number,
        default: null
    },
    variant: {
        type: String,
        default: 'normal' // "normal" | "solid" | "outline" | "line"
    },
    dimension: {
        type: String,
        default: 'medium' // "small" | "medium" | "big"
    },
    onSubmit: {
        type: Function,
        default: () => { }
    },
    onFocus: {
        type: Function,
        default: () => { }
    },
    onBlur: {
        type: Function,
        default: () => { }
    }
});

const onInput = (event) => {
    let value = event.target.value;
    if (props.type === 'number' && props.decimals) {
        const parts = value.replace(',', '.').split('.');
        value = parts.length > 1 ? _.join([ _.join(_.initial(parts), ''), _.last(parts).substring(0, props.decimals) ], '.') : value;
    }
    emit('update:modelValue', value);
}

const checkNumber = (event) => {
    if (props.type === 'number' && props.decimals !== null) {
        const parts = event.target.value.replace(',', '.').split('.');
        if ((props.decimals && (/[^\d,.]/.test(event.key) || (parts.length > 1 && _.last(parts).length === props.decimals))) || (!props.decimals && /[^\d]/.test(event.key))) {
            event.preventDefault();
        }
    }
}

const { $eventBus } = useNuxtApp();
const inputField = ref(null);

const focusInput = (value) => {
  nextTick(() => {
    if (props.name === value) {
        inputField.value.focus();
    }
  });
};

const focusError = (value) => {
  nextTick(() => {
    if (props.name === value && !!props.error) {
        inputField.value.focus();
    }
  });
};

onMounted(async () => {
  if (props.type === 'number' && props.decimals !== null && props.modelValue) {
    emit('update:modelValue', parseFloat(props.modelValue).toFixed(props.decimals).replace(/\.00/, ''));
  }
  $eventBus.on('focus:input', focusInput);
  $eventBus.on('focus:error', focusError);
});

onUnmounted(() => {
  $eventBus.off('focus:input', focusInput);
  $eventBus.off('focus:error', focusError);
});

</script>
