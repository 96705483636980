<template>
 <svg width="107" height="100" viewBox="0 0 107 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2054_82)">
<path d="M3.5908 54.8508C-2.21731 80.0407 6.73552 105.594 32.8025 97.358C48.3122 92.4591 89.2256 36.0146 104.404 50.4439" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M104.393 50.4439C108.993 38.8384 90.0388 40.1861 83.7707 43.994C72.101 50.4118 60.2816 56.5515 48.5263 62.8195C46.1624 63.8357 47.8524 66.2317 47.7241 68.1677" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M45.5739 54.5299C50.4942 46.743 -1.2974 46.7965 3.92241 54.8829C17.6565 57.7174 31.4655 58.4876 45.3065 54.5299" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.2567 1.06958C34.8348 5.34811 35.5193 15.5417 29.9679 21.0824C25.0369 26.0027 25.3364 36.667 30.2995 40.9134" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.0951 5.49792C25.9034 15.7022 9.97658 20.3872 17.7207 31.3082" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.96049 76.2328C7.64484 83.2817 14.8328 90.9937 22.6197 89.8492" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M48.5368 76.9922C48.3549 79.8374 47.403 82.7254 48.0127 85.5493" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M45.8413 54.7974C46.4617 57.557 46.9965 60.3488 47.7239 63.087" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_2054_82">
<rect width="105.359" height="100" fill="white" transform="translate(0.820557)"/>
</clipPath>
</defs>
</svg>
</template>
