<template>
  <div v-if="running && auth.idSupplier !== variant?.product?.supplier?.id" class="flex flex-col" @click="() => checkAuth()">
    <label
      htmlFor="placingBid"
      class="text-primary-dark font-bold text-base"
    >
      <div class="truncate">{{ $t('text-placing-bid') }}<span class="text-red-500">&nbsp;*</span></div>
    </label>
    <div class="flex flex-col sm:flex-row md:flex-col xl:flex-row items-center justify-between">
      <div class="w-full flex flex-row flex-wrap space-x-2 items-center justify-end mr-0 sm:mr-2">
        <div class="w-fit flex-1 flex flex-col justify-center min-w-150">
          <div class="w-full flex justify-stretch mb-1">
            <Input
              v-model="placingBid"
              name="placingBid"
              type="number"
              :required="true"
              :minvalue="minimalBid"
              :decimals="_.get(customer, 'currency.decimals', null)"
              variant="outline"
              class-name="w-full pt-1.5 text-bolder"
              class-border="border-0 border-l border-t border-b border-primary-dark rounded-l-full text-right"
              :on-submit="handleSave"
            />
            <div
              class="w-fit px-5 mt-1.5 rounded-r-full pl-0 bg-light flex items-center justify-center text-bolder border-0 border-r border-t border-b border-primary-dark"
            >
              {{ variant?.currency?.sign }}
            </div>
          </div>
        </div>
        <div class="w-fit flex space-x-2">
          <Button
              class="ms-auto"
              :disabled="updating"
              size="medium"
              variant="outline"
              :on-click="() => handlePlus(variant?.currency?.currencyRate < 20 ? 10 : 5)"
          >
            +{{ variant?.currency?.currencyRate < 20 ? 10 : 5 }}
          </Button>
          <Button
              class="ms-auto"
              :disabled="updating"
              size="medium"
              variant="outline"
              :on-click="() => handlePlus(variant?.currency?.currencyRate < 20 ? 50 : 10)"
          >
            +{{ variant?.currency?.currencyRate < 20 ? 50 : 10 }}
          </Button>
          <Button
              class="ms-auto"
              :disabled="updating"
              size="medium"
              variant="outline"
              :on-click="() => handlePlus(variant?.currency?.currencyRate < 20 ? 100 : 50)"
          >
            +{{ variant?.currency?.currencyRate < 20 ? 100 : 50 }}
          </Button>
        </div>
      </div>
      <Button
          class="ms-auto w-full sm:w-fit md:w-full xl:w-fit mt-1 sm:mt-0 md:mt-1 xl:mt-3 mb-0 lg:mb-3"
          :loading="updating"
          :disabled="updating"
          size="medium"
          :on-click="handleSave"
      >
        {{ $t('text-place-bid') }}
      </Button>
    </div>
    <Alert
      v-if="errorMsg"
      variant="error"
      :message="errorMsg"
      class="w-full mt-5"
      :closeable="true"
      :on-close="() => errorMsg = ''"
    />
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { useAuthStore } from '@stores/auth';
import { useCustomerStore } from '@stores/customer';
import Input from "@components/ui/input";
import Button from "@components/ui/button";
import Alert from "@components/ui/alert";

const props = defineProps({
  variant: {
    type: Object,
    required: true
  },
  auction: {
    type: Object,
    required: true
  }
});

const minimalBid = computed(() => (props.auction?.currentBid || props.auction?.startingBid) + (props.auction?.minIncrement || 0));

const { t } = useI18n();
const { $eventBus, $toast, $dayjs } = useNuxtApp();
const auth = useAuthStore();
const customer = useCustomerStore();
const placingBid = ref(minimalBid.value.toFixed(props.variant?.currency?.decimals).replace(/\.00/, ''));
const updating = ref(false);
const errorMsg = ref('');

const now = useNow();
const startTime = ref($dayjs(props.auction?.startAt));
const finishTime = ref($dayjs(props.auction?.finishAt));

const running = computed(() => startTime.value.isBefore(now.value) && now.value.isBefore(finishTime.value));

const isValid = () => {
  errorMsg.value = '';

  if ((+placingBid.value < minimalBid.value) || (+placingBid.value === (props.auction?.currentBid || props.auction?.startingBid))) {
    errorMsg.value = t('error.auction-bid.minimal', { amount: minimalBid.value.toFixed(props.variant?.currency?.decimals).replace(/\.00/, ''), sign: props.variant?.currency?.sign || '' });
  }

  if (errorMsg.value) {
    $eventBus.emit('focus:input', 'placingBid');
    return false;
  }

  return true;
}

const handleSave = async () => {
  if (!isValid()) {
    updating.value = false;
    return false;
  }
  updating.value = true;
  const result = await auctionBidCreate(props.auction.id, {
    amount: +placingBid.value,
  });
  if (result) {
    props.auction.currentBid = result?.currentBid || +placingBid.value;
    placingBid.value = minimalBid.value.toFixed(props.variant?.currency?.decimals).replace(/\.00/, '');
    if (import.meta.client) {
      $toast.success(t('success.auction-bid.create', { amount: result.amount, sign: props.variant?.currency?.sign || '' }));
    }
    $eventBus.emit('auction:bid:add', {
      id: props.auction.id,
      bid: result
    });
    $eventBus.emit('auction:update', {
      id: props.auction.id,
      currentBid: result?.currentBid || +placingBid.value
    });
  } else {
    if (import.meta.client) {
      $toast.success(t('error.auction-bid.create', { amount: placingBid.value, sign: props.variant?.currency?.sign || '' }));
    }
  }
  updating.value = false;
}

const handlePlus = (amount) => {
  placingBid.value = (+placingBid.value + amount).toFixed(props.variant?.currency?.decimals).replace(/\.00/, '');
}

const auctionUpdate = (value) => {
  if (props.auction?.id === value.id) {
    props.auction.currentBid = value.currentBid;
    if (+placingBid.value < minimalBid.value) {
      placingBid.value = minimalBid.value.toFixed(props.variant?.currency?.decimals).replace(/\.00/, '');
    }
  }
};

onMounted(async () => {
  $eventBus.on('auction:update', auctionUpdate);
});

onUnmounted(() => {
  $eventBus.off('auction:update', auctionUpdate);
});

</script>
