<template>
  <button
    v-if="variant === 'disabled'"
    :disabled="disabled || cart.loading"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-product-button-disabled rounded-full transition-colors bg-product-button-disabled"
    @click="onClick"
  >
    <slot />
  </button>
  <button
    v-else-if="variant === 'outline'"
    :disabled="disabled || cart.loading"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-primary-dark rounded-full transition-colors bg-light border border-primary-dark hover:border-primary-dark-hover hover:text-light hover:bg-primary-dark-hover"
    @click="onClick"
  >
    <slot />
  </button>
  <button
    v-else-if="variant === 'simple'"
    :disabled="disabled || cart.loading"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-product-button rounded-full transition-colors bg-product-button hover:text-product-button-hover hover:bg-product-button-hover focus:bg-product-button-hover"
    @click="onClick"
  >
    <slot />
  </button>
  <button
    v-else-if="variant === 'offer'"
    :disabled="disabled || cart.loading"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-product-button rounded-full transition-colors bg-product-button hover:text-product-button-hover hover:bg-product-button-hover focus:bg-product-button-hover"
    @click="onClick"
  >
    <slot />
  </button>
  <button
    v-else-if="variant === 'wanted'"
    :disabled="disabled || cart.loading"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-product-button rounded-full transition-colors bg-product-button hover:text-product-button-hover hover:bg-product-button-accent-hover focus:bg-product-button-accent-hover"
    @click="onClick"
  >
    <slot />
  </button>
  <button
    v-else-if="variant === 'auction'"
    :disabled="disabled || cart.loading"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-product-button rounded-full transition-colors bg-product-button hover:text-product-button-hover hover:bg-product-button-hover focus:bg-product-button-hover"
    @click="onClick"
  >
    <slot />
  </button>
</template>

<script setup>
import { useCartStore } from '@stores/cart';

defineProps({
  data: {
    type: Object,
    required: true
  },
  variant: {
    type: String,
    required: false,
    default: () => ''
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false
  },
  onClick: {
    type: Function,
    default: () => {}
  }
});

const cart = useCartStore();

</script>
