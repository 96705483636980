<template>
  <SidebarWrapper>
    <ul class="flex-grow">
      <template v-for="{ routeCode, label, icon } in siteSettings.mobileMenuLinks" :key="`${routeCode}_${label}`">
        <li v-if="routeCode !== ROUTES.CODE.AUCTIONS || configs.auctionFeature" :class="className">
          <span
            :class="[ 'flex items-center py-3 px-5 md:px-8 text-sm font-semibold transition duration-200 hover:text-accent', route.path === translatePath(routeCode) ? 'text-accent' : 'text-body-dark' ]"
            @click="() => handleClick(routeCode)"
          >
            <span v-if="icon" class="me-2">{{ icon }}</span>
            {{ $t(label) }}
          </span>
        </li>
      </template>
      <li class="py-3 px-5 md:px-8">
        <AddInspirationButton v-if="routeName.startsWith('inspiration___') || routeName.startsWith('inspiration-type-slug___')" />
        <AddAdButton v-else />
      </li>
    </ul>
  </SidebarWrapper>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import { siteSettings } from "@settings/site.settings";
import { useConfigStore } from '@stores/config';
import SidebarWrapper from "@components/common/sidebar/sidebar-wrapper";
import AddAdButton from "@components/layout/navbar/add-ad-button";
import AddInspirationButton from "@components/content/add-inspiration-button";

const configs = useConfigStore();
const route = useRoute();
const { $eventBus } = useNuxtApp();

const routeName = computed(() => route?.name || '');

const closeSidebar = () => {
    $eventBus.emit('sidebar:close');
}

const handleClick = async (routeCode) => {
    closeSidebar();
    await navigatorTo(translatePath(routeCode));
}

</script>
