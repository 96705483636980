<template>
  <div :class="['w-full', variant?.product?.type === PRODUCT_TYPE.AUCTION && position === 'inside' ? 'mt-8' : '']">
    <div :class="['w-full', variant?.product?.type === PRODUCT_TYPE.AUCTION ? (position === 'inside' ? 'block md:hidden' : 'hidden md:block') : (position === 'inside' ? 'block' : 'hidden')]">
      <ProductDetailInfoPrices :variant="variant" position="outside" />

      <div v-if="variant?.product?.type === PRODUCT_TYPE.AUCTION" class="mt-5 mb-5">
        <CountDown class-name="text-base font-bold" :status="variant?.auction?.status" :start-at="variant?.auction?.startAt" :finish-at="variant?.auction?.finishAt" :is-detail="true" />
      </div>

      <div :class="variant?.product?.type === PRODUCT_TYPE.AUCTION ? 'w-full' : 'w-full my-8'">
        <div class="w-full flex flex-col">
          <div v-if="store.action !== 'HIDE_ADD_TO_CART'" class="mb-3 lg:mb-0 w-full">
            <AddToCartBtn
              v-if="variant?.product?.status === STATUS.CLOSED"
              :data="variant"
              variant="disabled"
              :disabled="true"
              class="!w-full mb-5"
            >
              {{ $t('text-product-status-closed') }}
            </AddToCartBtn>
            <AuctionPlaceBid
              v-else-if="variant?.product?.type === PRODUCT_TYPE.AUCTION && auth?.idSupplier !== variant?.product?.supplier?.id"
              :variant="variant"
              :auction="variant.auction"
              class="!w-full"
            />
            <EditProductBtn
              v-else-if="_.get(variant, 'product.supplier.id') === auth?.idSupplier"
              :data="variant.product"
              variant="big"
              :class="variant?.product?.type === PRODUCT_TYPE.AUCTION ? '!w-full mb-5' : '!w-full'"
            />
            <AddToCart
              v-else
              :data="variant"
              variant="simple"
              :disabled="!isOrderable"
              class="!w-full"
            />
          </div>
        </div>
      </div>
      <AuctionBids v-if="variant?.product?.type === PRODUCT_TYPE.AUCTION && variant?.auction?.id" :variant="variant" class="mb-8" />
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { PRODUCT_TYPE, STATUS } from "@utils/constants";
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected';
import AddToCart from "@components/product/add-to-cart/add-to-cart";
import AddToCartBtn from "@components/product/add-to-cart/add-to-cart-btn";
import EditProductBtn from "@components/product/product-edit/edit-product-btn";
import CountDown from "@components/ui/countdown";
import AuctionPlaceBid from "@components/auction/auction-place-bid";
import AuctionBids from "@components/auction/auction-bids";
import ProductDetailInfoPrices from "@components/product/product-details/product-detail-info-prices";

const props = defineProps({
  variant: {
    type: Object,
    required: true
  },
  position: {
    type: String,
    default: 'inside' //inside, outside
  }
})

const auth = useAuthStore();
const store = useSelectedStore();

defineEmits(['select:tab:comments']);

const isOrderable = computed(() => {
  return _.get(props.variant, 'stockCount', 0) !== 0 && _.get(props.variant, 'availability.isProductOrderable', true);
});

</script>
