<template>
  <li>
    <button
      :class="[ 'flex items-center w-full pb-4 text-start outline-none focus:outline-none focus:ring-0 hover:no-underline', isActive ? (isDetail ? 'text-selected-detail focus:text-selected-detail' : 'text-selected-item focus:text-selected-item') : (isDetail ? 'text-bolder' : 'text-heading'), className ? className : 'text-base', depth || type === CATEGORY_TYPE.NEWS ? 'font-normal' : 'font-bold', isActive && depth ? 'underline': '' ]"
    >
      <div class="flex justify-between items-center w-full">
        <div class="w-full" @click="handleClick">
          {{ props.item?.name }}
        </div>
        <div v-if="(item.categories || []).length" :class="['ms-auto', isDetail ? 'text-bolder' : 'text-heading']" @click="toggle">
          <MinusIcon v-if="isOpen" class="w-6 h-6" />
          <PlusIcon v-else class="w-6 h-6" />
        </div>
      </div>
    </button>
  </li>
  <li v-if="isOpen">
    <ul class="ms-4">
      <SidebarMenuItem
        v-for="(currentItem) in item.categories || []"
        :key="currentItem.id"
        :item="currentItem"
        :depth="depth + 1"
        :type="type"
        :is-detail="isDetail"
        class-name="text-base ms-5"
      />
    </ul>
  </li>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { CATEGORY_TYPE } from "@utils/constants";
import { useUIStore } from '@stores/ui';
import PlusIcon from "@components/icons/plus-icon";
import MinusIcon from "@components/icons/minus-icon";

const props = defineProps({
  className: {
    type: String,
    default: () => ''
  },
  item: {
    type: Object,
    required: true,
    default: () => { }
  },
  type: {
    type: String,
    required: true
  },
  depth: {
    type: Number,
    default: 0
  },
  isDetail: {
    type: Boolean,
    default: false
  }
});

const ui = useUIStore();
const { $eventBus } = useNuxtApp();
const route = useRoute();
const routeCategory = useRouteCategory();

const canOpen = (value) => value && (!_.isEmpty(_.find(props.item.categories, { id: value })) || (value === props.item.id && props.item?.categories?.length));

const isOpen = ref(canOpen(routeCategory.idCategory.value));

watch(() => routeCategory.idCategory.value, (value) => {
  isOpen.value = value ? canOpen(value) : isOpen.value;
});

const isActive = computed(() => routeCategory.idCategory.value === props.item.id || (props.item.categories || []).some((item) => item.id === routeCategory.idCategory.value));

const routeName = computed(() => route?.name || '');

const toggleCollapse = () => {
  isOpen.value = !isOpen.value;
}

const closeSidebar = () => {
    $eventBus.emit('sidebar:close');
};

const categoryRouteCode = computed(() => {
  let routeCode = ROUTES.CODE.ADS;
  if (routeName.value.startsWith('offers___') || routeName.value.startsWith('offers-slug___')) {
    routeCode = ROUTES.CODE.OFFERS;
  } else if (routeName.value.startsWith('requests___') || routeName.value.startsWith('requests-slug___')) {
    routeCode = ROUTES.CODE.REQUESTS;
  } else if (routeName.value.startsWith('auctions___') || routeName.value.startsWith('auctions-slug___')) {
    routeCode = ROUTES.CODE.AUCTIONS;
  } else if (routeName.value.startsWith('inspiration___') || routeName.value.startsWith('inspiration-type-slug___')) {
    routeCode = ROUTES.CODE.INSPIRATION;
  } else if (routeName.value.startsWith('news___') || routeName.value.startsWith('news-about-slug___')) {
    routeCode = ROUTES.CODE.NEWS;
  } else if (routeName.value.startsWith('favorites___')) {
    routeCode = ROUTES.CODE.FAVORITES;
  }

  return routeCode;
});

const handleClick = async () => {
  if (!isOpen.value) {
    toggle();
  }
  if (ui.displaySidebar) {
    closeSidebar();
  }
  const params = { ...route.query };
  if (params.idCategory != props.item?.id) {
    params.idCategory = props.item?.id;
    _.forEach(_.keys(params), key => {
      if (/^p\[\d+\]$/.test(key)) {
        delete params[key];
      }
    });
  } else {
    delete params.idCategory;
  }
  await navigatorTo({
    path: translatePath(categoryRouteCode.value),
    query: params,
  });
}

const toggle = () => {
  if (Array.isArray(props.item?.categories) && !!props.item?.categories?.length) {
    toggleCollapse();
  }
}

</script>
