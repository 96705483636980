import { API_ENDPOINTS } from "@utils/api/endpoints";

export default async function (body) {
  const { data, error, status } = await useApiFetch(API_ENDPOINTS.CUSTOMER_SETTING_NOTIFICATION_EMAIL, {
    method: 'PUT',
    body
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'setting-notification-email-update',
      level: 'error',
      extra: { error: error.value }
    });

    return null;
  }

  return data.value;
}
