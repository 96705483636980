import _ from 'lodash-es';

export default function (id, name) {
  if (_.isNil(id) || !_.isInteger(id) || id === undefined || id === 'undefined' || (typeof id === 'string' && _.isEmpty(id.trim()))) {
    return '';
  }

  return _.chain(`${id}-${name ? name : ''}`)
    .deburr()
    .trim()
    .words()
    .join('-')
    .toLower()
    .replace(/[^a-z0-9-]/g, '')
    .replace(/-+$/g, '')
    .value();
}
