<template>
  <div class="py-6 px-5 sm:p-8 bg-light w-screen md:max-w-md h-screen md:h-auto flex flex-col justify-center">
    <h2 v-if="title" class="flex justify-center font-semibold text-lg md:text-xl text-bolder mb-4 text-center">
      {{ $t(title) }}
    </h2>
    <ActivationAlert :centered="true" class="mb-5" />
    <p v-if="content" class="text-body text-center">
      {{ $t(content) }}
    </p>
    <div class="flex flex-col sm:flex-row items-center justify-center w-full mt-5">
      <Button
        class="sm:mr-3 mt-5 sm:mt-0 w-full sm:w-fit"
        :on-click="handleClose"
      >
        <span class="text-sm">{{ $t('text-close') }}</span>
      </Button>
    </div>
  </div>
</template>

<script setup>
import Button from "@components/ui/button";
import ActivationAlert from "@components/profile/activation-alert";

defineProps({
  title: {
    type: String,
    default: ''
  },
  content: {
    type: String,
    default: ''
  }
});

const { $eventBus } = useNuxtApp();

const handleClose = () => {
  $eventBus.emit('modal:close');
};

</script>
