<template>
  <div class="h-15 bg-primary-dark bradcrumbs-radial-gradient rounded-b-panel text-light flex items-center justify-between">
    <ul class="flex flex-nowrap items-center list-none px-5 lg:px-10 py-2 overflow-hidden mr-5">
      <li class="pl-3 hidden lg:flex items-center space-x-3">
        <Link
          :href="translatePath(ROUTES.CODE.ADS)"
          class="tracking-wider whitespace-nowrap text-sm lg:text-base hover:underline"
          @click="onClose"
        >
          {{ $t('text-all-ads') }}
        </Link>
        <ChevronRight class="w-3 h-3" />
      </li>
      <li
        v-for="(category, idx) in breadcrumbs"
        :key="category.id"
        class="flex flex-row flex-nowrap items-center"
      >
        <ChevronRight v-if="idx > 0" class="w-3 h-3" />
        <Link
          :href="translatePath(baseRouteCode, slugify(category?.id, category?.name))"
          class="tracking-wider whitespace-nowrap text-sm lg:text-base hover:underline px-3"
          @click="onClose"
        >
          {{ category.name }}
        </Link>
      </li>
    </ul>
    <div class="mt-2">
      <slot />
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { useCategoryStore } from '@stores/category';
import Link from "@components/ui/link/link";
import ChevronRight from "@components/icons/outline/chevron-right";

const props = defineProps({
  categories: {
    type: Array,
    default: () => []
  },
  onClose: {
    type: Function,
    default: () => { }
  },
  baseRouteCode: {
    type: String,
    default: ROUTES.CODE.CATEGORY
  }
})

const categories = useCategoryStore();
const breadcrumbs = ref(props.categories);

const categoryPath = (childCategoryId, currentCategories = categories.eshop || [], path = []) => {
  for (const category of currentCategories) {
    if (category.id === childCategoryId) {
      return [...path, category];
    }
    const newPath = categoryPath(childCategoryId, category.categories, [...path, category]);
    if (newPath) return newPath;
  }

  return undefined;
}

const updateBreadcrumbs = (value) => {
  breadcrumbs.value = categoryPath(_.first(props.categories)?.id, value || categories.eshop || []) || props.categories;
}

watch(() => categories.eshop, (value) => {
  updateBreadcrumbs(value)
});

onServerPrefetch(() => {
  updateBreadcrumbs();
});

onMounted(() => {
  updateBreadcrumbs();
});

</script>
