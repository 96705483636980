<template>
    <a :href="path" :to="{ path, query, hash, external }" :active-class="activeClass" @click.prevent="handleClick">
      <slot />
    </a>
</template>

<script setup>
import _ from 'lodash-es';

const props = defineProps({
    href: {
        type: String,
        default: ''
    },
    query: {
        type: Object,
        default: () => {}
    },
    hash: {
        type: String,
        default: ''
    },
    activeClass: {
        type: String,
        default: ''
    },
    external: {
        type: Boolean,
        default: false
    }
});

const route = useRoute();

const path = computed(() => {
    const to = props.href || route.path;
    if (_.isNil(to) || to === undefined || to === 'undefined' || (typeof to === 'string' && _.isEmpty(to.trim()))) {
        sentryCaptureEvent({
            message: 'link.undefined',
            level: 'error',
            extra: { props, to }
        });

        return '/';
    }

    return to;
});

const handleClick = async () => {
    await navigatorTo({ path: path.value, query: props.query, hash: props.hash, external: props.external });
}

</script>
