<template>
    <div v-if="!finished && !auction?.winningBid && bids.items.length && (!auction?.reservePrice || bids.items[0]?.amount >= auction?.reservePrice)">
        <h2 class="text-base text-primary-dark font-bold pb-3">
            {{ $t('text-auction-current-winner') }}
        </h2>
        <ProfileHeader :data="bids.items[0]" :type="CART_TYPE.BUYER" />
    </div>
</template>

<script setup>
import ProfileHeader from "@components/profile/profile-header";

const props = defineProps({
    auction: {
        type: Object,
        required: true
    },
    bids: {
        type: Object,
        required: true
    }
});

const { $dayjs } = useNuxtApp();

const now = useNow();
const finishTime = ref($dayjs(props.auction?.finishAt));
const finished = computed(() => finishTime.value.isBefore(now.value));

</script>
