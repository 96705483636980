import _ from 'lodash-es';

export default async function (to, options = {}) {
  if (_.isNil(to) || (_.isObject(to) && _.isNil(_.get(to, 'path'))) || to === undefined || to === 'undefined' || (typeof to === 'string' && _.isEmpty(to.trim()))) {
    sentryCaptureEvent({
        message: 'navigator-to.undefined',
        level: 'error',
        extra: { to }
    });

    return await navigateTo('/');
  } else {
    return await navigateTo(to, options);
  }
}
