<template>
  <Link
    :href="translatePath(ROUTES.CODE.HOME)"
    class="relative flex-shrink-0 inline-flex w-fit h-fit"
    :aria-label="$t('nav-home')"
  >
    <img
      :src="`/logo/${config.public.layoutDesign}-${variant}.svg`"
      :alt="$t('nav-home')"
      :width="siteSettings.logo[config.public.layoutDesign]?.header?.width || 126"
      :height="siteSettings.logo[config.public.layoutDesign]?.header?.height || 60"
      :class="siteSettings.logo[config.public.layoutDesign]?.header?.class || ''"
    >
  </Link>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import { siteSettings } from "@settings/site.settings";
import Link from "@components/ui/link/link";


defineProps({
  variant: {
    type: String,
    default: 'black'
  }
});

const config = useRuntimeConfig();

</script>
