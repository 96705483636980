<template>
  <div class="py-6 px-5 sm:p-8 bg-light w-fit h-fit flex flex-col justify-center rounded-panel">
    <h2 class="flex font-semibold text-lg md:text-xl text-bolder mb-2">
      {{ $t('text-share-news') }}
    </h2>
    <hr class="mb-2 md:mb-4" >
    <div class="flex flex-row space-x-5 items-start justify-start my-5">
      <a
        v-for="(data, idx) in siteSettings.shareLinks"
        :key="idx"
        :href="link(data.href)"
        class="flex flex-col items-center justify-center space-y-3 hover:text-primary-dark-hover hover:underline focus:text-primary-dark-hover focus:underline cursor-pointer w-20"
        target="_blank"
        :aria-label="$t('text-share')"
      >
        <component :is="data.icon" class="w-6 h-6" />
        <div class="text-sm flex items-center justify-center">
          <div class="text-center">{{ $t(data.label) }}</div>
        </div>
      </a>
      <div
        class="flex flex-col items-center justify-center space-y-3 hover:text-primary-dark-hover hover:underline focus:text-primary-dark-hover focus:underline cursor-pointer w-20"
        @click="handleCopy"
      >
        <ClipboardCopy class="w-6 h-6" />
        <div class="text-sm flex items-center justify-center">
          <div class="text-center">{{ $t('text-copy-to-clipboard') }}</div>
        </div>
      </div>
    </div>
    <div class="flex flex-col sm:flex-row items-center justify-center w-full">
      <Button
          class="sm:mr-3 mt-5 sm:mt-0 w-full sm:w-fit"
          variant="outline"
          :on-click="handleClose"
      >
        {{ $t('text-close') }}
      </Button>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { useSelectedStore } from '@stores/selected';
import { siteSettings } from "@settings/site.settings";
import Button from "@components/ui/button";
import ClipboardCopy from "@components/icons/outline/clipboard-copy";

const { $eventBus } = useNuxtApp();
const store = useSelectedStore();
const config = useRuntimeConfig();
const routeLang = useRouteLang();

const url = computed(() => {
  const lang = routeLang.code.value;
  const section = ROUTES[lang] || ROUTES[siteSettings.language.code];
  const prefix = lang === siteSettings.language.code ? '' : `/${lang}`;

  return `${config.public.appBaseUrl}${prefix}${section['NEWS']}/${slugify(store.content?.id, store.content?.name)}`;
});

const link = (href) => {
  const name = `${store.content?.name}`;

  return _.replace(_.replace(href, '{URL}', encodeURIComponent(url.value)), '{TEXT}', encodeURIComponent(name));
}

const handleClose = () => {
  $eventBus.emit('modal:close');
  if (store.action === 'RETURN_TO_NEWS_MODAL') {
    $eventBus.emit('modal:open', 'NEWS_DETAILS');
    store.setAction(null);
  }
};

const handleCopy = async () => {
  if (import.meta.client) {
    navigator.clipboard.writeText(url.value).then(() => {
      $eventBus.emit('toast:success', 'text-copied-to-clipboard');
    });
  }
}

</script>
