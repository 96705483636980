<template>
<svg width="71" height="100" viewBox="0 0 71 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2054_49)">
<path d="M59.9643 36.7957C60.2079 44.9488 57.3447 52.4114 68.889 50.7971C69.2342 64.9609 68.818 79.0233 68.8789 93.1872C68.8687 96.6698 66.5538 99.005 63.0204 98.9949C51.1715 98.9644 39.3327 98.8223 27.4838 98.9136C24.1129 98.9441 21.0872 95.8575 21.1075 92.4866C21.2192 78.6375 21.5644 64.0878 22.0924 50.6042" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.3361 50.3503C26.1233 35.0899 32.6824 3.98012 32.6824 3.98012C32.6824 3.98012 15.4116 -3.14751 11.401 4.69085C9.00482 9.38168 4.23276 22.0226 2.23256 34.633C-0.0316329 48.9288 13.1169 49.9543 21.8285 50.858" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M60.929 49.7411C48.3084 50.3097 34.571 50.3909 22.0925 50.594" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M45.3637 6.02087C51.9126 13.8592 58.6036 25.2716 54.3697 35.8818C46.8562 35.8513 39.0687 35.8209 31.0272 35.7904C31.0069 40.8264 30.9866 45.4767 30.9663 50.1269" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M45.3637 6.02087C39.5256 14.641 32.6213 24.0938 35.8501 35.171" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M28.0728 79.1248C27.6362 85.1863 26.8544 94.04 35.7995 94.1923" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M60.9289 48.9797C68.3713 46.9693 68.483 37.7399 60.208 36.2068C58.5327 35.892 56.167 35.8616 54.3699 35.8819" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M45.3333 12.6206C47.7599 14.7426 49.5367 18.6923 50.3693 21.7789" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M45.3333 12.6206C43.9017 15.5854 42.1045 18.3674 40.48 21.2306" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_2054_49">
<rect width="69.073" height="100" fill="white" transform="translate(0.963379)"/>
</clipPath>
</defs>
</svg>
</template>
