<template>
<svg width="103" height="100" viewBox="0 0 103 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2054_70)">
<path d="M40.361 42.9306C20.6674 143.024 151.927 96.0823 79.6078 1.16943C63.4343 11.9518 49.1786 24.5117 40.361 42.9306ZM40.361 42.9306C38.4548 41.1648 35.8937 38.6855 34.5254 37.4225" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M53.8329 21.3776C41.4718 5.7186 20.6555 4.38542 2.16641 2.99377C-1.48229 27.6342 8.79722 61.1507 36.8758 64.9397" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M23.7196 15.238C25.7311 26.7572 20.3282 27.4237 10.5632 26.3478" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M75.468 23.7166C72.4508 43.7727 68.1472 63.6534 64.9429 83.6511" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M59.0955 51.1987C61.6332 52.0524 62.101 54.6837 63.4107 56.5081C69.0124 66.8577 73.8189 58.5429 80.7304 54.7071" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M63.7734 29.1311C70.7317 40.9543 73.4097 42.5213 83.9465 32.7798" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M35.9989 28.1019C37.3204 37.7382 33.8003 40.8022 23.4272 38.9194" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M56.1719 73.7107C58.616 77.4179 60.9199 81.4057 64.9428 83.6511" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M76.345 76.7864C73.0589 79.5697 69.0477 82.2711 64.9429 83.6628" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.6318 25.2603C25.9414 28.5698 30.3502 32.9786 33.6598 36.2882" stroke="#E1A140" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_2054_70">
<rect width="102.432" height="100" fill="white" transform="translate(0.283691)"/>
</clipPath>
</defs>
</svg>
</template>
