<template>
  <li>
    <div
      :class="[ 'flex items-center w-full pb-4 text-start outline-none font-bold focus:outline-none focus:ring-0 hover:no-underline', _.isEmpty(route.query) && _.isEmpty(route.params) ? (isDetail ? 'text-selected-detail focus:text-selected-detail' : 'text-selected-item focus:text-selected-item') : (isDetail ? 'text-bolder' : 'text-heading'), className ? className : 'text-base' ]"
       @click="onClick"
    >
      {{ $t(title) }}
    </div>
  </li>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { useUIStore } from '@stores/ui';

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  className: {
    type: String,
    default: () => ''
  },
  isDetail: {
    type: Boolean,
    default: false
  }
});

const { $eventBus } = useNuxtApp();
const ui = useUIStore();
const route = useRoute();

const routeName = computed(() => route?.name || '');

const title = computed(() => {
  return {
    content: 'text-all-inspirations',
    news: 'text-all-news'
  }[props.type] || 'text-all-ads';
});

const routeCode = computed(() => {
  return {
    content: ROUTES.CODE.INSPIRATION,
    news: ROUTES.CODE.NEWS
  }[props.type] || ROUTES.CODE.ADS;
});

const closeSidebar = () => {
    $eventBus.emit('sidebar:close');
};

const onClick = async () => {
  if (ui.displaySidebar) {
    closeSidebar();
  }
  if (routeName.value.startsWith('favorites___')) {
    await navigatorTo(translatePath(ROUTES.CODE.FAVORITES));
  } else  {
    await navigatorTo(translatePath(routeCode.value));
  }
}

</script>
