<template>
  <div class="py-6 px-5 sm:p-8 bg-light w-100vw sm:w-70vw h-100vh sm:h-auto flex flex-col justify-center sm:rounded-panel">
    <h1 class="mb-5 sm:mb-8 text-lg sm:text-xl text-bolder font-semibold">
      {{ $t('change-password') }}
    </h1>

    <form class="flex flex-col" @submit.prevent="">
      <PasswordInput
        v-model="oldPassword"
        name="oldPassword"
        :label="$t('text-old-password')"
        :error="errors?.oldPassword?.message ? $t(errors.oldPassword.message) : ''"
        class-name="mb-5"
        variant="outline"
      />
      <PasswordInput
        v-model="newPassword"
        name="newPassword"
        :label="$t('text-new-password')"
        :error="errors?.newPassword?.message ? $t(errors.newPassword.message) : ''"
        class-name="mb-5"
        variant="outline"
      />
      <PasswordInput
        v-model="newPasswordRepeat"
        name="newPasswordRepeat"
        :label="$t('text-confirm-password')"
        :error="errors?.newPasswordRepeat?.message ? $t(errors.newPasswordRepeat.message) : ''"
        class-name="mb-5"
        variant="outline"
      />
      <Alert
        v-if="errorMsg"
        variant="error"
        :message="errorMsg"
        class="mb-6"
        :closeable="true"
        :on-close="() => errorMsg = ''"
      />
      <Button
        :loading="loading"
        :disabled="loading"
        class="ms-auto"
        :on-click="handleSubmit"
      >
        {{ $t('text-submit') }}
      </Button>
    </form>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import Button from "@components/ui/button";
import PasswordInput from "@components/ui/password-input";
import Alert from "@components/ui/alert";

const loading = ref(false); //TODO
const oldPassword = ref('');
const newPassword = ref('');
const newPasswordRepeat = ref('');
const errors = ref({});
const errorMsg = ref('');

const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();

const closeModal = async () => {
  $eventBus.emit('modal:close');
}

const changePassword = async(body, callbacks) => {
    errors.value = null;
    loading.value = true;
    const { data, error, status } = await useApiFetch(API_ENDPOINTS.CUSTOMER_CHANGE_PASSWORD, {
      method: 'PUT',
      body
    });
    if (status.value === 'error') {
      callbacks.onError(error.value);
    } else {
      callbacks.onSuccess(data.value);
    }
    loading.value = false;
};

const isValid = () => {
  errors.value = {};
  errorMsg.value = '';
  if (!oldPassword.value) {
    errors.value.oldPassword = { message: 'error-old-password-required' };
  }
  if (!newPassword.value) {
    errors.value.newPassword = { message: 'error-new-password-required' };
  }
  if (!newPasswordRepeat.value) {
    errors.value.newPasswordRepeat = { message: 'error-confirm-password' };
  }
  if (newPassword.value !== newPasswordRepeat.value) {
    errors.value.newPasswordRepeat = { message: 'error-match-passwords' };
  }
  if (Object.keys(errors.value).length !== 0) {
    errorMsg.value = 'error-some-fields-required';
    $eventBus.emit('focus:error', _.first(_.keys(errors.value)));
    return false;
  }

  return true;
}

const handleSubmit = async () => {
  if (!isValid()) {
    return;
  }
  await changePassword(
    {
      oldPassword: oldPassword.value,
      newPassword: newPassword.value,
      newPasswordRepeat: newPasswordRepeat.value
    },
    {
      onSuccess: () => {
        oldPassword.value = '';
        newPassword.value = '';
        newPasswordRepeat.value = '';
        $toast.success(t('password-successful'));
        closeModal();
      },
      onError: (error) => {
        if (error?.data?.statusCode === 400) {
          if (error?.data?.data?.code === 1035) {
            errors.value = { oldPassword: { message: 'error.old-password.invalid' } };
          } else if (error?.data?.data?.code === 1036) {
            errors.value = { newPassword: { message: 'error-match-passwords' } };
          }
        } else if (error?.data?.statusCode === 401) {
          errors.value = { newPasswordRepeat: { message: 'error.unauthorized' } };
        }
        sentryCaptureEvent({
          message: 'profile-change-password.handleSubmit',
          level: 'error',
          extra: { error }
        });
      },
    }
  );
}

</script>
