import _ from 'lodash-es';
import { useCategoryStore } from '@stores/category';

export default function (type) {
  const items = ref([]);
  const categoryType = ref(type);
  const categoryStore = useCategoryStore();

  const walkCategory = (category, level) => {
    category.level = level;
    items.value.push(category);
    if (_.has(category, 'categories')) {
      _.forEach(category?.categories || [], (item) => {
        item.parent = category;
        walkCategory(item, level + 1);
      });
    }
  };

  const setup = (values) => {
    items.value = [];
    _.forEach(_.cloneDeep(values || []), (item) => {
      walkCategory(item, 0);
    });
  }

  setup(categoryStore[categoryType.value]);

  watch(() => categoryStore[categoryType.value], (values) => setup(values));

  return { items };
}
