<template>
    <ClientOnly>
        <VueCountdown
            v-if="status === STATUS_ACTIVE && running"
            v-slot="{ days, hours, minutes, seconds }"
            :time="diff"
            class="w-full" @end="onCountdownEnd"
        >
            <div
                :class="['flex flex-row items-center', running ? 'justify-between' : 'justify-start', className, isDetail ? 'flex-wrap' : 'w-full']">
                <div v-if="!running || days + hours + minutes + seconds === 0" class="text-primary-neutral">
                    {{ $t('text-auction-finished') }}
                </div>
                <div v-else-if="isDetail" class="text-primary-dark mr-2 w-auto">
                    <span v-if="now.isBefore(startTime)">
                        {{ $t('text-start-of-auction') }}: <span class="font-normal">{{ $luxon.fromISO(props.startAt).setLocale(routeLang.code.value).toFormat('cccc') }} {{ $luxon.fromISO(props.startAt).setLocale(routeLang.code.value).toLocaleString($luxon.DATETIME_MED) }}</span>
                    </span>
                    <span v-else-if="now.isBefore(finishTime)">
                        {{ $t('text-end-of-auction') }}: <span class="font-normal">{{ $luxon.fromISO(props.finishAt).setLocale(routeLang.code.value).toFormat('cccc') }} {{ $luxon.fromISO(props.finishAt).setLocale(routeLang.code.value).toLocaleString($luxon.DATETIME_MED) }}</span>
                    </span>
                </div>
                <div v-else class="w-full text-primary-dark">
                    <span v-if="now.isBefore(startTime)">
                        {{ $t('text-starting-in') }}:
                    </span>
                    <span v-else-if="now.isBefore(finishTime)">
                        {{ $t('text-finishing-in') }}:
                    </span>
                </div>
                <div v-if="!running || days + hours + minutes + seconds === 0" />
                <div v-else-if="days + hours + minutes === 0 && seconds" class="whitespace-nowrap">
                    {{ isDetail ? $t('text-in-less-than-one-minute') : $t('text-less-than-one-minute') }}
                </div>
                <div
                    v-else-if="days + hours === 0 && minutes < 5 && seconds"
                    :class="['flex flex-row items-center justify-end whitespace-nowrap rounded-full', isDetail ? 'px-2 text-sm bg-accent-light' : '']"
                >
                    <ClockIcon :class="[isDetail ? 'w-4 h-4' : 'w-3 h-3']" />&nbsp;
                    <div v-if="minutes">{{ minutes }}:</div>
                    <div>{{ seconds < 10 ? `0${seconds}` : seconds }}</div>
                </div>
                <div
                    v-else
                    :class="['flex items-center justify-end whitespace-nowrap space-x-1', isDetail ? 'w-auto ml-auto text-right' : 'w-full', days + hours === 0 ? 'text-primary-dark' : (now.isBefore(startTime) ? 'text-primary-dark' : (now.isBefore(finishTime) ? 'text-primary-dark' : '')) ]"
                >
                    <div v-if="days">{{ $t('text-countdown-days-with-count', { count: days, plural: days }) }}</div>
                    <div v-if="days || hours">{{ $t('text-countdown-hours-with-count', { count: hours, plural: hours
                        }) }}</div>
                    <div v-if="days || hours || minutes">{{ $t('text-countdown-minutes-with-count', { count:
                        minutes, plural: minutes }) }}</div>
                </div>
            </div>
        </VueCountdown>
        <div v-else :class="['w-full flex flex-row items-center justify-start', className]">
            <div class="text-primary-neutral">
                {{ $t('text-auction-finished') }}
            </div>
        </div>
    </ClientOnly>
</template>

<script setup>
import { STATUS_ACTIVE } from "@utils/constants";
import ClockIcon from "@components/icons/outline/clock";

const props = defineProps({
    status: {
        type: String,
        default: ''
    },
    startAt: {
        type: String,
        default: ''
    },
    finishAt: {
        type: String,
        default: ''
    },
    isDetail: {
        type: Boolean,
        default: false
    },
    className: {
        type: String,
        default: ''
    }
})

const { $dayjs, $luxon } = useNuxtApp();
const routeLang = useRouteLang();
const running = ref(true);

const now = useNow();
const startTime = ref($dayjs(props.startAt));
const finishTime = ref($dayjs(props.finishAt));
const time = ref(null);

if (now.value.isBefore(startTime.value)) {
    time.value = startTime.value;
} else if (now.value.isBefore(finishTime.value)) {
    time.value = finishTime.value;
} else {
    running.value = false;
}

const diff = ref(time.value ? time.value.diff(now.value, 'millisecond') : null);

const onCountdownEnd = () => {
    if (now.value.isBefore(finishTime.value)) {
        time.value = finishTime.value;
        diff.value = time.value.diff(now.value, 'millisecond');
    } else {
        running.value = false;
    }
}

</script>
