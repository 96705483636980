<template>
  <div v-if="loading && !isLocal" class="grid place-items-center h-40 pt-10">
    <Spinner :text="$t('text-loading')" class="w-10 h-10" />
  </div>
  <div v-else class="min-h-screen grid place-items-center p-4 sm:p-8">
    <div class="text-center">
      <p class=" text-body-dark text-sm uppercase tracking-widest mb-4 sm:mb-5">
        {{ $t('404-heading', { code: error?.statusCode || 404 }) }}
      </p>
      <h1 class="font-bold text-2xl leading-normal sm:text-3xl text-bolder mb-5">
        {{ $t('404-sub-heading') }}
      </h1>
      <VueJsonPretty v-if="isLocal" class="mt-10 block" :data="error" />
      <div v-else class="mb-11">
        <Image
          src="/no-result.svg"
          :alt="$t('404-heading')"
          :width="600"
          :height="453"
        />
      </div>
      <Link
        :href="translatePath(ROUTES.CODE.HOME)"
        class="inline-flex items-center sm:text-base text-bolder underline focus:outline-none hover:no-underline hover:text-body-dark"
      >
        {{ $t('404-back-home') }}
      </Link>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import Spinner from "@components/ui/loaders/spinner/spinner";
import Image from "@components/ui/image";
import Link from "@components/ui/link/link";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

const props = defineProps({
  error: {
    type: Object,
    default: () => {}
  }
})

const config = useRuntimeConfig();
const reload = ref(0);
const loading = ref(props.error?.statusCode === 500);

const isLocal =  (process.env.NODE_ENV === 'development') || (config.apiBaseUrl === 'https://for-aquarist.test');

onMounted(() => {
  sentryCaptureEvent({
    message: 'pages/error',
    level: 'error',
    extra: { error: props.error }
  });
  if (import.meta.client && window && loading.value && !isLocal) {
    if (window?.location?.hash && window?.location?.hash.startsWith('#reload-')) {
      reload.value = parseInt(window?.location?.hash.slice(8));
      loading.value = loading.value && reload.value <= 3;
    }
    if (reload.value < 3) {
      _.delay(() => {
        reload.value++;
        window.location.hash = `#reload-${reload.value}`;
        window.location.reload();
      }, 5000);
    } else {
      _.delay(() => {
        reload.value++;
        if (reload.value <= 4) {
          window.location.href = `/#reload-${reload.value}`;
        }
      }, 5000);
    }
  }
});

</script>
